import { memo, useMemo, type ReactNode, useCallback, useReducer } from 'react';

import { type AppAction } from './types/AppAction';
import { type AppState } from './types/AppState';
import AppContext from './private/AppContext';
import readAppState from './private/readAppState';
import saveAppState from './private/saveAppState';

type Props = {
  children?: ReactNode;
};

export default memo(function AppProvider({ children }: Props) {
  const reducer = useCallback((state: AppState, action: AppAction): AppState => {
    if (action.type === 'MARK_ALL_AS_NOT_DONE') {
      state = { ...state, routineItems: state.routineItems.map(item => ({ ...item, whenDone: undefined })) };
    } else if (action.type === 'MARK_AS_DONE') {
      state = {
        ...state,
        routineItems: state.routineItems.map(item => ({
          ...item,
          whenDone: action.payload.itemId === item.id && !item.whenDone ? new Date() : item.whenDone
        }))
      };
    } else if (action.type === 'MARK_AS_NOT_DONE') {
      state = {
        ...state,
        routineItems: state.routineItems.map(item => ({
          ...item,
          whenDone: action.payload.itemId === item.id ? undefined : item.whenDone
        }))
      };
    }

    return state;
  }, []);

  const [state, dispatch] = useReducer(reducer, undefined, readAppState);

  const markAllAsNotDone = useCallback(() => dispatch({ type: 'MARK_ALL_AS_NOT_DONE' }), [dispatch]);

  const markAsDone = useCallback(
    (itemId: string) => dispatch({ payload: { itemId }, type: 'MARK_AS_DONE' }),
    [dispatch]
  );

  const markAsNotDone = useCallback(
    (itemId: string) => dispatch({ payload: { itemId }, type: 'MARK_AS_NOT_DONE' }),
    [dispatch]
  );

  useMemo(() => saveAppState(state), [state]);

  const context = useMemo(
    () => ({
      markAllAsNotDone,
      markAsDone,
      markAsNotDone,
      state
    }),
    [markAsDone, markAsNotDone, state]
  );

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
});
