import { createContext } from 'react';
import { AppState } from '../types/AppState';

type ContextType = {
  markAllAsNotDone: () => void;
  markAsDone: (itemId: string) => void;
  markAsNotDone: (itemId: string) => void;
  state: AppState;
};

const AppContext = createContext<ContextType>({} as ContextType);

export default AppContext;
