import { useContext } from 'react';

import { type AppState } from './types/AppState';
import AppContext from './private/AppContext';

export default function useAppState(): AppState {
  const { state } = useContext(AppContext);

  return state;
}
