import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';

import App from './ui/App';
import AppProvider from './data/AppProvider';

const rootElement = document.getElementsByTagName('main')[0];

rootElement &&
  createRoot(rootElement).render(
    <StrictMode>
      <AppProvider>
        <App />
      </AppProvider>
    </StrictMode>
  );
