import {
  memo,
  type MouseEventHandler,
  type PointerEvent as ReactPointerEvent,
  type PointerEventHandler,
  type PropsWithChildren,
  useCallback,
  useEffect
} from 'react';
import classNames from 'classnames';

import { useStateWithRef } from 'use-state-with-ref';

type Props = PropsWithChildren<{
  className?: string;
  disabled?: true | undefined;
  holdClassName?: string;
  durationInMS?: number;
  onLongTap?: () => void;
  onTap?: () => void;
}>;

export default memo(function LongTapButton({
  children,
  className,
  disabled,
  durationInMS = 1_500,
  holdClassName,
  onLongTap,
  onTap
}: Props) {
  const [downPointerId, setDownPointerId, downPointerIdRef] = useStateWithRef<number | undefined>();

  const handleContextMenu = useCallback<MouseEventHandler<unknown>>(event => event.preventDefault(), []);

  const handlePointerDown = useCallback<PointerEventHandler<unknown>>(
    ({ pointerId }) => setDownPointerId(value => value || pointerId),
    [setDownPointerId]
  );

  const handlePointerLeave = useCallback<(event: ReactPointerEvent) => void>(
    ({ pointerId }) => {
      setDownPointerId(value => (value === pointerId ? undefined : value));
    },
    [setDownPointerId]
  );

  const handlePointerUp = useCallback<(event: PointerEvent) => void>(
    ({ pointerId }) => {
      downPointerIdRef.current === pointerId && onTap?.();
      setDownPointerId(value => (value === pointerId ? undefined : value));
    },
    [downPointerIdRef, setDownPointerId]
  );

  useEffect(() => {
    window.addEventListener('pointerup', handlePointerUp);

    return () => window.removeEventListener('pointerup', handlePointerUp);
  }, [handlePointerUp]);

  useEffect(() => {
    if (downPointerId) {
      const timeout = setTimeout(() => {
        onLongTap?.();
        setDownPointerId(undefined);
      }, durationInMS);

      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [downPointerId, onLongTap, setDownPointerId]);

  return (
    <div
      className={classNames(className, downPointerId ? holdClassName : undefined)}
      onContextMenu={disabled ? undefined : handleContextMenu}
      onPointerDown={disabled ? undefined : handlePointerDown}
      onPointerLeave={disabled ? undefined : handlePointerLeave}
    >
      {children}
    </div>
  );
});
