import { type AppState } from '../types/AppState';

export default function createDefaultAppState(): AppState {
  return {
    routineGroups: [
      { id: 'g-00001', name: '開店' },
      { id: 'g-00002', name: '營業期間' },
      { id: 'g-00003', name: '關店' }
    ],
    routineItems: [
      { groupId: 'g-00001', id: 'i-00001', name: '已打卡' },
      { groupId: 'g-00001', id: 'i-00002', name: '已檢查冰箱溫度並記錄' },
      { groupId: 'g-00001', id: 'i-00003', name: '已核對開店現金 $382' },
      { groupId: 'g-00001', id: 'i-00004', name: '已檢查貨架及產品是否整潔' },
      { groupId: 'g-00001', id: 'i-00005', name: '已清潔冰箱玻璃窗以及收銀機屏幕' },
      { groupId: 'g-00001', id: 'i-00006', name: '已打開所以燈及招牌' },
      { groupId: 'g-00001', id: 'i-00007', name: '已開門營業' },
      { disabled: true, groupId: 'g-00002', id: 'i-00008', name: '佩戴公司提供的圍裙或員工姓名牌' },
      { disabled: true, groupId: 'g-00002', id: 'i-00009', name: '需能接受長期站立' },
      { disabled: true, groupId: 'g-00002', id: 'i-00010', name: '適當時可坐下休息，但有顧客進店必須站立並問候' },
      { disabled: true, groupId: 'g-00002', id: 'i-00011', name: '有顧客進店需放下手機或停下手上工作接待' },
      { disabled: true, groupId: 'g-00002', id: 'i-00012', name: '能及時為顧客提供解答和服務' },
      { disabled: true, groupId: 'g-00002', id: 'i-00013', name: '熟悉店内商品，推銷商品' },
      { disabled: true, groupId: 'g-00002', id: 'i-00014', name: '避免與顧客發生正面衝突' },
      { disabled: true, groupId: 'g-00002', id: 'i-00015', name: '可接聽緊急電話，但不可在營業地點長時間講電話' },
      { disabled: true, groupId: 'g-00002', id: 'i-00016', name: '保持收銀檯 / 桌面整潔' },
      { groupId: 'g-00003', id: 'i-00017', name: '已掃地、拖地、倒垃圾 (下班前 30 分鐘)' },
      { groupId: 'g-00003', id: 'i-00018', name: '已檢查冰箱門關好且冰箱正常工作，記錄溫度' },
      { groupId: 'g-00003', id: 'i-00019', name: '已放好當日現金' },
      { groupId: 'g-00003', id: 'i-00020', name: '已放好明天的開店現金 $382' },
      { groupId: 'g-00003', id: 'i-00021', name: '已關閉照明設備及招牌' },
      { groupId: 'g-00003', id: 'i-00022', name: '已打卡' },
      { groupId: 'g-00003', id: 'i-00023', name: '已鎖門' }
    ]
  };
}
