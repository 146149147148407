import './App.css';

import { Fragment, memo } from 'react';

import LongTapButton from './private/LongTapButton';
import RoutineGroup from './RoutineGroup';
import RoutineItem from './RoutineItem';
import useAppState from '../data/useAppState';
import useMarkAllAsNotDone from '../data/useMarkAllAsNotDone';

export default memo(function App() {
  const state = useAppState();
  const markAllAsNotDone = useMarkAllAsNotDone();

  return (
    <Fragment>
      <header className="app-header">
        <img alt="" className="app-header__logo" src="assets/images/logo.png" />
        <h1 className="app-header__title">Meet-O-Meat Daily</h1>
      </header>
      {state?.routineGroups.map(({ id, name }) => (
        <RoutineGroup header={name}>
          {state?.routineItems
            .filter(({ groupId }) => groupId === id)
            .map(({ disabled, id, name, whenDone }) => (
              <RoutineItem disabled={disabled} id={id} name={name} whenDone={whenDone} />
            ))}
        </RoutineGroup>
      ))}
      <div>
        <small>* 長按項目即可取消所選，時間將會重新計算。</small>
      </div>
      <footer>
        <br />
        <LongTapButton
          className="app__new-shift-button"
          holdClassName="app__new-shift-button--hold"
          onLongTap={markAllAsNotDone}
        >
          <button className="app__new-shift-button__actual-button" type="button">
            開始新一班 (長按)
          </button>
        </LongTapButton>
      </footer>
    </Fragment>
  );
});
