import { AppState } from '../types/AppState';

export default function saveAppState(state: AppState) {
  const whenDoneMap = new Map<string, string>();

  state.routineItems.forEach(({ id, whenDone }) => whenDone && whenDoneMap.set(id, whenDone.toISOString()));

  try {
    localStorage.setItem(
      'appState',
      JSON.stringify({ whenDoneMap: Object.fromEntries(Array.from(whenDoneMap.entries())) })
    );
  } catch (error) {
    // Intentionally left blank.
  }
}
