import { memo, useCallback, useMemo } from 'react';

import LongTapButton from './private/LongTapButton';
import useMarkAsDone from '../data/useMarkAsDone';
import useMarkAsNotDone from '../data/useMarkAsNotDone';

type Props = {
  disabled?: true | undefined;
  id: string;
  name: string;
  whenDone?: Date | undefined;
};

const TIMESTAMP_FORMAT: Intl.DateTimeFormatOptions = {
  timeStyle: 'short',
  timeZone: 'America/Los_Angeles'
};

export default memo(function RoutineItem({ disabled, id, name, whenDone }: Props) {
  const markAsDone = useMarkAsDone();
  const markAsNotDone = useMarkAsNotDone();

  const handleLongTap = useCallback(() => markAsNotDone(id), [id, markAsNotDone]);
  const handleTap = useCallback(() => markAsDone(id), [id, markAsDone]);

  const timestamp = useMemo(() => new Intl.DateTimeFormat('en-US', TIMESTAMP_FORMAT).format(whenDone), []);

  return (
    <li className="routine-group__item">
      <LongTapButton
        className="routine-group__item-label"
        holdClassName="routine-group__item-label--hold"
        disabled={disabled}
        onLongTap={handleLongTap}
        onTap={handleTap}
      >
        {!disabled && <input checked={!!whenDone} disabled={disabled} type="checkbox" />}
        <span className="routine-group__item-name">{name}</span>
        {whenDone && <span className="routine-group__item-when-done">{timestamp}</span>}
      </LongTapButton>
    </li>
  );
});
