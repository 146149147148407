import { type AppState } from '../types/AppState';
import createDefaultAppState from './createDefaultAppState';

export default function readAppState(): AppState {
  const state = createDefaultAppState();

  try {
    const { whenDoneMap } = JSON.parse(localStorage.getItem('appState') || '');

    for (const [id, whenDoneISOString] of Object.entries<string>(whenDoneMap)) {
      const whenDone = new Date(whenDoneISOString);

      if (!isNaN(+whenDone)) {
        const item = state.routineItems.find(item => item.id === id);

        if (item) {
          item.whenDone = whenDone;
        }
      }
    }
  } catch (error) {
    // Intentionally left blank.
  }

  return state;
}
