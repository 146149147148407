import './RoutineGroup.css';

import { memo, type PropsWithChildren } from 'react';

import RoutineGroupHeader from './RoutineGroupHeader';

type Props = PropsWithChildren<{
  header: string;
}>;

export default memo(function RoutineGroup({ children, header }: Props) {
  return (
    <section className="routine-group">
      <RoutineGroupHeader name={header} />
      <ul className="routine-group__list">{children}</ul>
    </section>
  );
});
